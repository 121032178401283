// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `x.angular-cli.json`.

export const environment = {
  production: false,
  apiurl: 'api/',
  authurl: 'api/',
  chatHub: 'api/chat',
  adminChatHub: 'api/adminchat',
  videoHub: 'https://jitsi.dotenterprise.com',
  videoHubDomain: 'jitsi.dotenterprise.com',
  videoAuthDomain: 'https://virtualexpo.info',
  API_BASE_PATH: '.',
  versionCheckURL : '/version.json',
  cdnURL: 'https://virtualexpo.fra1.digitaloceanspaces.com',
  imageUploadSize: 5242880,
  fileUploadSize: 33554432,
  watchdogInterval: 10000,
  sentryEnabled: true,
  sentryDsn: 'https://76b0690649b84d8e93ea9d53424fff4d@debug.nethotline.io/2',
  agoraId: '7619ac48cb274b109606888a71d9a8a0',
  enableOneOnOne: true,
  enableSessions: true,
  timeStamp: '2025-01-21 17:10:55 UTC',
  webexIntegrationPartnerId: 'df022c4e-d85e-4594-a161-03247f5465fd',
  betaServer: 'beta.virtualexpo.info'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
